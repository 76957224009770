import * as React from "react"
import Layout from "../components/layout/layout";

const Page404 = () => {
    return (
        <Layout isFrontPage={false}>
            404
        </Layout>
    )
}

export default Page404
